.App {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.App-header {
  width: 100%;
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  /* ヘッダーをページの一番上に固定 */
  border-bottom: 2px solid black;
  z-index: 100;
  padding: 0 16px;
  box-sizing: border-box;
}

.App-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* 親要素の高さいっぱいに広げる */
}

.App-logo img {
  max-height: 100px;
  @media (max-width: 768px) {
      width: 300px;
  }
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}




.backgroundColor-profile {
  background: linear-gradient(-20deg, #ff16e8 0%, #3223ff 100%);
  box-sizing: border-box;
}

.backgroundColor-odd {
  background-color: #f1ffff;
  box-sizing: border-box;
}

.backgroundColor-even {
  background-color: #fff;
  box-sizing: border-box;
}

.App-footer {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  overflow: hidden;
}

.backgroundColor-copyright {
  background-color: #ddd;
  /* 背景色を統一 */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App-body {
  width: 100%;
  margin-top: 100px;
  padding: 0% !important;
  margin-left: 0% !important;
  /* ヘッダーの高さ分だけ余白を追加 */
}

.Tab-container {
  width: 100%;
}

.Tab-contents{
  color: white;
  background-color: rgb(30, 5, 45);
  height: 50px;
}

.MuiTabs-indicator {
  background-color: #ffffff !important;
}

.backgroundColor-anotherContents {
  background-color: rgb(30, 30, 30);
}
.QR-code-wrapper {
    margin-top: 100px;
    background-color: rgb(30, 30, 30);
    padding-top: 100px;
}

.QR-code-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.url-input-field {
    display: flex;
    justify-content: center;
}

.QR-code-img {
    display: flex;
    justify-content: center;
}

.textfield {
    justify-content: center;
    width: 80%;
    height: 40px;
    max-width: 750px;
    font-size: 18px;
    padding: 0 20px;
    border: 2px solid #fff694;
    border-radius: 20px;
    background-color: #000;
    color: white;
    margin: 0, auto;
}

.textfield::placeholder {
    color: white;
    opacity: 0.5;
}

.textfield:focus {
    outline: none;
    border-color: white;
}

.QRCodeCanvas {
    width: 250px !important;
    height: 250px !important;
    margin: 30px;
    margin-top: 40px;
}

.downloadButton {
    background-color: #fff694;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 auto;
}

.downloadButton svg {
    color: #000;
    font-size: 50px;
}

.downloadButton:hover {
    background-color: #f0f0f0;
}

.generateButton {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.neon {
    width: 250px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff694;
    cursor: pointer;
    border: #fff694 0.125rem solid;
    border-radius: 0.25rem;
}

.neon:hover {
    border: white 0.125rem solid;
    color: white
}
/* CSSの競合:
CSSの指定が正しく適用されていない可能性があります。いくつかの原因が考えられますので、以下を確認してみてください。

1. CSSの競合:
　他のCSSルールが競合している可能性があります。特にMUIのコンポーネントは内部でスタイルを持っていることがあるため、それが干渉しているかもしれません。

2．CSSの指定の優先度:
　CSSの優先度が低いために変更が反映されていない可能性があります。
　特にMUIのコンポーネントには強いスタイルが設定されている場合があるため、!importantを使ってみると良いでしょう。
*/

.date {
    font-size: 1.2em !important;
}

.title {
    font-size: 1.25em !important;
    font-weight: bold !important;
}

.content {
    font-size: 1.1em;
}

.HistoryContents {
    text-align: left;
}
.LoginForm-wrapper {
    margin-top: 100px;
    background-color: rgb(30, 30, 30);
    padding-top: 100px;
    text-align: center;
}

.LoginForm-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 0 0 15px 2.5px rgba(225, 225, 225);
    width: 325px;
    height: 300px;
    margin: auto;
}

.loginForm-title {
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
}

.loginForm-input-field{
    position: relative;
    width: 100%;
    padding-right: 40px; /* アイコンのスペースを確保 */
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.loginForm-textfield {
    width: 75%;
    height: 30px;
    padding: 0 15px;
    border: 2px solid #fff694;
    border-radius: 20px;
    background-color: #000;
    color: white;
    font-size: 1rem;
}

.loginForm-textfield::placeholder {
    color: white;
    opacity: 0.5;
}

.loginForm-textfield:focus {
    outline: none;
    border-color: rgb(255, 255, 255);
}

.loginForm-input-icon {
    width: 80%;
    position: absolute;
    right: 70px; /* 右端からの位置 */
    color: #ffffff; /* アイコンの色（必要に応じて変更） */
    font-size: 1.45rem !important;
    padding: 2.5px;
}

.loginForm-click-icon {
    cursor: pointer;
}

.loginForm-forgot-password {
    color: white;
    width: 150px;
    text-align: center;
    margin-left: 15px;
    cursor: pointer;
    font-size: 0.8rem;
}

.loginForm-forgot-password:hover {
    text-decoration: underline;
}

.loginForm-button {
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.login-button-style {
    width: 150px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff694;
    cursor: pointer;
    border: #fff694 0.125rem solid;
    border-radius: 1rem;
}

.login-button-style:hover {
    border: rgb(255, 255, 255) 0.125rem solid;
    color: rgb(255, 255, 255)
}

.loginForm-registration-message {
    color: white;
    margin-top: 10px;
}

.loginForm-create-account {
    font-weight: bold;
    color: #1b94e0;
}

.loginForm-create-account:hover {
    text-decoration: underline;
    cursor: pointer;
}



/* ANOVA用 */
.presentation-title {
    color: white;
    width: 75%;
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.excel-download-button-style {
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff694;
    cursor: pointer;
    border: #fff694 0.125rem solid;
    border-radius: 0.25rem;
}

.excel-download-button-style:hover {
    border: rgb(255, 255, 255) 0.125rem solid;
    color: rgb(255, 255, 255)
}

.loginForm-button2 {
    display: flex;
    justify-content: center;
    margin-top: 0px;
    gap: 100px;
}
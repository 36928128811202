.ProjectList {
    width: 100%;
    margin: 0, auto;
}

.card-container {
    width: 100%;
    display: flex;
    flex-direction: row; /* 横に配置 */
    flex-wrap: wrap; /* 横に入りきらない場合の折り返し */
    align-items: flex-start; /* 上揃えにする */
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    margin: 20px 0px 5px 0px;
    background-color: white;
}

.card-image {
    display: contents;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    box-sizing: border-box;
}

.card-image img {
    width: 300px;
    height: auto;
    margin: 10%;
}

.card-content {
    padding: 20px;
    min-width: 500px; /* 最小幅を設定 */
    display: inline-block;
	justify-content: center;
	text-align: center;
	vertical-align: top;
	flex-wrap: wrap;
    box-sizing: border-box;
}

.sub-cards {
    display: flex;
    justify-content: space-around; /* 均等に並べる */
    margin-bottom: 10px;
    width: 100%; /* 幅を100%に設定 */
    box-sizing: border-box;
}

.sub-card {
    background-color: white; /* サブカードの背景色 */
    border: 1px double black;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 20%;
    max-width: 300px;
    height: 30px;
    border-radius: 100px;
    flex: 1; /* 均等に幅を取る */
    margin: 0 10px; /* サブカード同士の間隔を設定 */
    display: flex; /* フレックスボックスを有効にする */
    align-items: center; /* 垂直方向の中央揃え */
    justify-content: center; /* 水平方向の中央揃え */
}

.card-description {
    margin-bottom: 10px;
}

.play-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.title {
    font-size: 30px; /* タイトルの文字を大きく */
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}

.card-content .title::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px; /* 下線の太さ */
    background-color: black; /* 下線の色 */
    margin-top: 5px; /* 下線とテキストの間に少し余白を追加 */
}






































.gameCard {
	margin: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-content: flex-end;
  }

  .gameCard_Small {
	margin: auto;
	width: 150px;
	display: flex;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-content: stretch;
  }

  .card-radius_02{
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 4px 15px rgba(0,0,0, 0.25);
	margin : 20px 5px 30px 5px;
  }

  .card-radius_02game{
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 4px 15px rgba(0,0,0, 0.25);
	margin : 15px;
  }

  .card_02Game{
	text-align: center;
	vertical-align: middle;
  }

  .card_02Game {
	background-color: #fff;
	box-shadow: 0 0 0px rgba(0, 0, 0, .16);
	color: #000;
	text-align: center;
	width: 100%;
  }

  .card_02Game img{
	margin:0 auto;
  }

  .cardContent img{
	width: 230px;
	height: 230px;
	margin-top: 2.5em;
	margin-bottom: 2.5em;
	margin-left: 0.5em;
	margin-right: 0.5em;
  }
  
  .card__body_02 {
	padding: 0 2rem;
  }
  
  .card__text_02 {
	font-size: .8rem;
	text-decoration: none;
  }

  .cardContent{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-content: center;
  }

  .card__Gameimage {
	display: contents;
	width: 30%;
	min-height: 230px;
	text-align: center;
	justify-content: center;
	align-items: center;
	margin: 0;
  }

  .card__body_game {
	display: inline-block;
	justify-content: center;
	width: 75%;
	text-align: center;
	vertical-align: top;
	flex-wrap: wrap;
  }

  .card__gameTitle {
	height: fit-content;
	padding: 1rem 1.2rem 0;
	font-size: 1.75rem;
	order: 1;
	font-weight: bold;
	text-decoration: none;
	/*線の種類（実線） 太さ 色*/
	border-bottom: solid 3px #000;
	text-align: center;
	margin-right: 1em;
  }

  .card__gameTitle p{
	margin-bottom: 0;
  }

  .card__gameDiscription{
	text-align: justify;
	display: inline-flex;

	font-size: 13.5px;
	margin-top: 1;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-content: stretch;
  }

  .card__gameDiscription p {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
	text-align:justify;
	width: 100%;
	margin-right: 1em;
  }

  .NameCardDisplay{
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
  }